import React from 'react'
import Site from '../components/Layout/Site'
import DefaultVector from '../components/Shared/DefaultVector'
import { Col, Container, Row } from '../components/UI/Grid'
import { Section } from '../components/UI/Section'
import { Heading, Paragraph } from '../components/UI/Typography'
import styled from 'styled-components'
import { compose, space, style } from 'styled-system'
import Seo from '../components/Layout/Seo'
import { StaticImage } from 'gatsby-plugin-image'

const SicCapability = () => {
  return (
    <Site>
      <Seo title="SiC Process Capability" />
      <Section defaultVector py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container pb={10}>
            <Heading size="h1" mb={10} textAlign="center">
              SiC Process Capability
            </Heading>
            <Row justifyContent="space-between" alignItems="center" gutterSize={10}>
              <Col col={{ lg: 9 }} mb={{ _: 20 }}>
                <Heading size="h3" mb={4}>
                  FASTECH SiC{' '}
                  <Subtitle>Process capability and technology are keys to success</Subtitle>
                </Heading>
                <Paragraph>
                  Since 2015, Fastech has prioritized the assembly and testing of silicon carbide
                  (SiC) discrete devices, laying the foundation for robust and innovative process
                  capabilities. Our ongoing dedication to enhancing and discovering efficient
                  processing methods has facilitated faster time-to-market, improved yields, and
                  reduced overall costs for our customers. Presently, over ten companies trust
                  Fastech for their SiC product assembly and testing needs. In the coming year, we
                  are excited to expand our services to include power module packaging, further
                  strengthening our commitment to delivering exceptional solutions in the power
                  semiconductor packaging space.
                </Paragraph>
              </Col>
              <Col col={{ lg: 3 }} mb={{ _: 6, lg: 10 }}>
                <StaticImage src="../images/sic.png" alt="rf and modules" layout="fullWidth" />
              </Col>
            </Row>

            {/* <Heading size="h2" textAlign="center" mb={6}>
              SIC process capability and technology developed over the years
            </Heading> */}

            <Heading size="h4" mb={2}>
              Wafer Saw
            </Heading>

            <Paragraph mb={2}>
              A critical process that can affect yield, Fastech has developed custom recipes to
              achieve optimal results in yield, cost, and process throughput. To date we have sawn
              over 15,000 SiC wafers of varying physical characteristics.
            </Paragraph>

            <Ul mb={10}>
              <li>Optimized parameters for cutting rpm, feed rate, torque, and coolant.</li>
              <li>Material technology on blade size, thickness, and diamond abrasives.</li>
              <li>
                Design rule for wafer thickness (~100 um ~ 381 um), street width (down to 58 um) and
                material morphology.
              </li>
            </Ul>

            <Heading size="h4" mb={2}>
              Die Attach
            </Heading>

            <Paragraph mb={4}>
              Fastech provides a variety of die attach technology options tailored to suit the
              specific application, performance requirements, and cost objectives of SiC devices.
            </Paragraph>
            <TableContainer mb={10}>
              <Table>
                <thead>
                  <tr>
                    <th>Die Attach Material and Process</th>
                    <th>
                      Performance-
                      <br />
                      Solder Voids
                    </th>
                    <th>Cost-Material</th>
                    <th>Cost-Process</th>
                    <th>Process Throughput</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Soft Solder - Standard (spank)</td>
                    <td>10-15%</td>
                    <td>Low</td>
                    <td>Low</td>
                    <td>Mid</td>
                  </tr>
                  <tr>
                    <td>Soft Solder - Next Gen (write)</td>
                    <td>Target &lt; 10%</td>
                    <td>Low</td>
                    <td>Mid-High</td>
                    <td>Mid-High</td>
                  </tr>
                  <tr>
                    <td>Pre-form Solder (VLO)</td>
                    <td>&lt;4%</td>
                    <td>Mid</td>
                    <td>High</td>
                    <td>Low</td>
                  </tr>
                  <tr>
                    <td>Ag Sintered Epoxy</td>
                    <td>&lt;1%</td>
                    <td>High</td>
                    <td>Low</td>
                    <td>High</td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
            <Heading size="h4" mb={2}>
              Encapsulation Materials
            </Heading>

            <Paragraph mb={2}>
              As SiC discrete devices are designed to operate at higher junction temperatures, the
              demand for encapsulants with correspondingly higher glass transition temperatures has
              emerged. Fastech has successfully qualified new molding compounds that meet stringent
              AEC Q101 certifications*¹ for our line of discrete packages. We provide a range of
              compounds for:
            </Paragraph>

            <Ul mb={10}>
              <li>
                Standard applications{' '}
                <i>
                  <b>T</b>j
                </i>{' '}
                of 125 degrees C
              </li>
              <li>
                Enhanced applications (i.e. automotive){' '}
                <i>
                  <b>T</b>j
                </i>{' '}
                175 degrees C
              </li>
            </Ul>

            <Heading size="h4" mb={2}>
              Product Test and Options
            </Heading>

            <Paragraph mb={2}>
              The emergence of SiC devices has necessitated testing capabilities for higher voltages
              and currents. Fastech offers test platforms equipped with a wide range of options and
              combinations for standard DC, high voltage, high current, delta, and unclamped
              inductive load (UIL) tests, available in both single and multi-pass configurations.
              Additionally, we are actively developing AC testing capabilities for power modules.
              Our current testing capabilities include:
            </Paragraph>
            <TableContainer mb={10}>
              <ProductTable>
                <tr>
                  <td>Standard DC Test</td>
                  <td>20A/1000V</td>
                  <td>Delta</td>
                  <td></td>
                </tr>
                <tr>
                  <td>High Voltage</td>
                  <td>10mA/3.6KV</td>
                  <td rowSpan={2}>Unclamped Inductive Load</td>
                  <td>200A/2500V/0.01-159.9mH</td>
                </tr>
                <tr>
                  <td>High Current</td>
                  <td>200A/25V</td>
                  <td>100A/3600V/1mH,5mH,10mH,20mH(3x)</td>
                </tr>
              </ProductTable>
            </TableContainer>

            <Heading size="h4" mb={2}>
              Packaging Options
            </Heading>

            <Paragraph mb={10}>
              Available and currently in volume production for SiC devices are JEDEC TO247, TO220,
              TO263 and SOT227 with various lead configurations and substrate options. (See our
              package offering). For power modules, engineering prototypes of FASTPAK 1 & 2 are now
              available (equivalent to EASYPACK/ACEPACK 1 & 2).
            </Paragraph>

            <Heading size="h4" mb={2}>
              Notes: AEC Q101 Qualified Devices
            </Heading>

            <Paragraph>
              I. Three customers qualified on TO 247 4L 1200V SiC MOSFET, TO 263 7L 1200V SiC MOSFET
              and TO247 3L 650V GaNFET.
            </Paragraph>
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

const Ul = styled.ul`
  ${compose(space)};
`

const ProductTable = styled.table`
  text-align: left;
  width: 100%;
  ${compose(space)};

  tr td:first-child {
    background-color: #5467ce;
    color: #fff;
  }

  tr td:nth-child(3) {
    background-color: #5467ce;
    color: #fff;
  }

  tr td:last-child {
    background-color: #fff;
    color: #263238;
  }

  th,
  td {
    padding: 6px 12px;
    border: 1px solid #000;
  }
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  ${compose(space)};

  & + & {
    margin-top: 24px;
  }

  thead {
    tr {
      th {
        border: 1px solid #000;
        padding: 6px 12px;
        font-weight: 600;
      }

      &:first-child {
        th {
          background: #5467ce;
          color: #fff;
          font-size: 18px;

          &:last-child {
          }
        }
      }

      &:nth-child(2) {
        th {
          background: #5467ce;
          color: #fff;
          border-left: 1px solid #5467ce;

          &:last-child {
            border-right: 1px solid #5467ce;
          }
        }
      }

      &:nth-child(3) {
        th {
          background: #fff;
          color: var(--text);
          border-left: 1px solid #9e9e9e;

          &:last-child {
            border-right: 1px solid #9e9e9e;
          }
        }
      }
    }
  }

  tbody {
    td {
      border: 1px solid #000;
      padding: 6px 12px;

      &:last-child {
        border-right: 1px solid #9e9e9e;
      }
    }
  }
`

const TableContainer = styled.div`
  overflow-x: auto;
  max-width: 100%;
  ${compose(space)};

  @media screen and (max-width: 768px) {
    th,
    td {
      white-space: nowrap;
    }

    table {
      width: auto;
      min-width: 100%;
    }
  }
`

const Subtitle = styled.span`
  font-weight: 400;
  font-style: italic;
`

export default SicCapability
